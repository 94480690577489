import React from 'react'
import styles from './News.module.scss'

const news_items = [
    {
        date: '2025.03.13',
        description: '<a href="/privacy">プライバシーポリシー</a>, <a href="/commerce">特定商取引法に基づく表記</a>の情報を更新しました。'
    },
    {
        date: '2020.04.12',
        description: '<a href="/about">About</a>の情報を更新しました。'
    },
    {
        date: '2019.11.08',
        description: '<a href="/about">About</a>, <a href="/services">Services</a>を更新しました。'
    },
    {
        date: '2019.03.06',
        description: 'Webサイトを開設しました。会社概要は<a href="/about">こちら</a>'
    },
];

const NewsItem = ({ item }) => {
    const date = item.date;
    const description = item.description;
    return(
        <li>
            <span>{date}</span>
            <span dangerouslySetInnerHTML={{__html: description}} />
        </li>
    );
}

export default () => (
    <section className={`${styles.news} l-wrapper l-container`}>
        <div>
            <h2 className={`head`}>News</h2>
            <h3 className={styles.title}>最新情報</h3>
            <ul className={styles.items}>
                {news_items.map(item => <NewsItem item={item} />)}
            </ul>
        </div>
    </section>
)